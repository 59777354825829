import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuthStore();

  const redirectedWhenLoggedInPath = ['/reset-password', '/forgot-password', '/login'];

  if (auth.isLoggedIn) {
    if (redirectedWhenLoggedInPath.includes(to.path)) {
      return navigateTo('/');
    }
  }
});
